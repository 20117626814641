/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Rails from 'rails-ujs';

import './lib/jquery.min';

import 'popper.js';
import 'bootstrap';

import 'jquery.cookie';
import 'jquery.easing';
import 'jquery-pjax';
import Masonry from 'masonry-layout';

// npm packageに存在しないのでlib下からimport
import './lib/jquery.endless-scroll';
import './lib/jquery.remotipart';
import './lib/jquery.iframe-transport';

import 'lodash';

Rails.start();

// keyCode
const ENTER = 13;

const isset = data => {
  return typeof data !== 'undefined';
};

let msnry = null;

const alignUnits = () => {
  const bookmarks = document.querySelector('#bookmarks');
  if (msnry) {
    msnry.destroy();
  }
  msnry = new Masonry(bookmarks, {
    columnWidth: 292,
    itemSelector: '.bookmark',
    transitionDuration: 0,
    resize: false
  });
};

const switchView = _mode => {
  const mode =
    _mode || $('body').attr('data-view') || $.cookie('viewmode') || 'normal';
  $('body').attr('data-view', mode);
  if (mode === 'normal') {
    if (msnry) {
      msnry.destroy();
    }
  } else {
    alignUnits();
  }
  $('#viewbtn-normal,#viewbtn-fullsize').removeClass('current');
  $(`#viewbtn-${mode}`).addClass('current');

  $.cookie('viewmode', _mode, { path: '/' });
};

// unitサイズや画面サイズに応じた１行あたりのunit数を返す
const getUnitColumns = (_unit, _container) => {
  // Main
  let unitMargin =
    parseInt(_unit.css('margin-left'), 10) +
    parseInt(_unit.css('margin-right'), 10);
  let unitWidth = _unit.outerWidth() + unitMargin;

  // Followページでは、unitクラスが存在しないので、決め打ち。
  if (!unitMargin) {
    unitMargin = 22;
    unitWidth = 268 + unitMargin;
  }

  return Math.floor(_container.width() / unitWidth);
};

// ウィンドウサイズに応じていくつ読込むか計算
const howManyLoad = () => {
  const unit = $('#bookmarks .unit');
  const rows = Math.floor($(window).height() / 250);
  const col = getUnitColumns(unit, $('.container'));
  const screens = 3; // 何画面分読み込むか

  // 最終行の空欄数
  const blank = unit.length ? col - (unit.length % col) : 0;

  return col * rows * screens + blank;
};

$(() => {
  // -------------- settings
  $(window).resize(document.resizeWindow);
  $('#viewbtn-fullsize').on('click', () => {
    switchView('fullsize');
    ga(
      'gtm2.send',
      'event',
      'Switch view',
      'Fullsize view',
      `Fullsize view button on ${$('body').data('page-name')} page`
    );
  });
  $('#viewbtn-normal').on('click', () => {
    switchView('normal');
    ga(
      'gtm2.send',
      'event',
      'Switch view',
      'Normal view',
      `Normal view button on ${$('body').data('page-name')} page`
    );
  });

  // Dialog
  if (window.location.hash === '#sign_up') {
    $.get('/users/sign_up');
  }

  const dialogPop = $('#dialog');

  const deleteFromSelect = (select, val) => {
    // DS202: Simplify dynamic range loops
    // for (i = 0; i < select.find('option').length; i++)
    const end = select.find('option').length;
    for (
      let i = 0, asc = end >= 0;
      asc ? i < end : i > end;
      asc ? (i += 1) : (i -= 1)
    ) {
      if (
        select
          .find('option')
          .eq(i)
          .html() === val
      ) {
        select
          .find('option')
          .eq(i)
          .remove();
      }
    }
    return select;
  };
  // コレクションのEDIT
  dialogPop.on('change', '#collection-select', e => {
    const selectedColTitle = $(e.currentTarget)
      .find('option:selected')
      .html();
    const selectedColId = $(e.currentTarget).val();
    const selectClone = $(e.currentTarget).clone();
    $(e.currentTarget)
      .hide()
      .remove();

    const newSelect = deleteFromSelect(selectClone, selectedColTitle);

    const selectedHtml = `<input type='hidden' name='bookmark[collection_ids][]' value='${[
      selectedColId
    ]}'><input class='selected-collection' type='text' value='${selectedColTitle}' id='${selectedColId}' name='${selectedColId}' readonly>`;

    if (dialogPop.find('.newCollection').length) {
      dialogPop.find('.newCollection:eq(0)').before(selectedHtml);
      dialogPop.find('.newCollection:eq(0)').before(newSelect);
    } else {
      dialogPop.find('#add_selection').before(selectedHtml);
      dialogPop.find('#add_selection').before(newSelect);
    }
  });

  dialogPop.on('click', '.selected-collection', e => {
    const selectedTitle = $(e.currentTarget).val();
    const selectedId = $(e.currentTarget).attr('id');
    if (!$(e.currentTarget).hasClass('new')) {
      dialogPop
        .find('#collection-select')
        .append(`<option value='${selectedId}'>${selectedTitle}</option>`);
    }
    $(e.currentTarget)
      .hide()
      .remove();
    $(`#cID_${selectedId}`)
      .hide()
      .remove();
  });

  dialogPop.on('change', '.newCollection', e => {
    const selectedColTitle = $(e.currentTarget).val();
    $(e.currentTarget).remove();
    const selectedHtml = `<input class='selected-collection new' type='text' name='bookmark[new_collection_names][]' value='${selectedColTitle}' readonly>`;

    dialogPop.find('#collection-select').before(selectedHtml);
  });

  $('#dialog')
    .on('click', '.cancel-btn,#submitbtn', () => {
      $('#overlay').removeClass('visible');
      $('body').removeClass('no-scroll');
    })
    .on('click', '.close_error_btn', e => {
      e.preventDefault();
      e.stopPropagation();
      $('#error_explanation').hide();
    })
    .on('keypress', 'textarea', e => {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === ENTER) {
        e.preventDefault();
        $('#dialog input[type="submit"]').trigger('click');
      }
    });

  let mousedownTarget = null;
  $(document)
    .submit(() => $('#signUpBtn').removeAttr('data-disable-with'))
    .on('mousedown', '#overlay', e => {
      mousedownTarget = e.target;
    })
    .on('mouseup', '#overlay', e => {
      if (e.currentTarget === e.target && mousedownTarget === e.target) {
        // click
        $('#overlay').removeClass('visible');
        $('body').removeClass('no-scroll');
      }
      mousedownTarget = null;
    })
    .on('click', '[data-remote="true"]', e => {
      if ($(e.currentTarget).is('.edit_btn,.collect_btn')) {
        $(e.currentTarget)
          .parent('.bookmark')
          .addClass('loading');
      } else if ($(e.currentTarget).parents('#dialog').length) {
        // do nothing
      } else {
        $('body').addClass('loading');
      }
    })
    .on('click', '#auth-facebook-disconnect', e => {
      $.ajax({
        url: '/auth/facebook-disconnect',
        success() {
          $(e.currentTarget)
            .parent()
            .append(
              '<a href="javascript:void(0)" class="btn" id="auth-facebook-connect" target="_blank">connect</a>'
            );
          $(e.currentTarget).remove();
        }
      });
    })
    .on('click', '#auth-facebook-connect', e => {
      const self = $(e.currentTarget);
      const authWindow = window.open(
        '/auth/facebook?origin=/auth/return',
        'auth-window',
        'width=400, height=300, menubar=no, toolbar=no, scrollbars=yes'
      );
      $(authWindow).one('unload', () => {
        self
          .parent()
          .append(
            '<a href="javascript:void(0)" class="btn" id="auth-facebook-disconnect" target="_blank">disconnect</a>'
          );
        self.remove();
      });
    })
    .on('click', '#add_selection', e => {
      const self = $(e.currentTarget);
      self.before("<input class='newCollection' type='text' value='' >");
      self
        .siblings('select:hidden')
        .first()
        .show();
    })
    .on('click', '.close_recommended_users', () => {
      $('#recommended_users').hide();
      $.cookie('closed_recommended_users', 'true', { expires: 30 });
    })
    .on('click', '.fullsizeViewBtn', e => {
      $('.capture').removeClass('captureNormal');
      $('.capture').addClass('captureFullsize');
      $(e.currentTarget).hide();
    })
    .on('click', '.moreCollectorsBtn', e => {
      $('.collectorList .user').removeClass('hidden');
      $(e.currentTarget).hide();
    })
    .on('mouseover', '#bm .collected.btn', e => {
      $(e.currentTarget).html('Go to yours ?');
    })
    .on('mouseout', '#bm .collected.btn', e => {
      $(e.currentTarget).html('Collected');
    })
    .endlessScroll({
      ceaseFire() {
        // trueを返すとcallbackが呼ばれない
        if ($('body').is('.overlayed')) {
          return true;
        }
        if ($('#infinite-scroll').length) {
          return false;
        }
        return true;
      },
      callback:
        $('body').attr('data-page-name') === 'users#index'
          ? document.loadUsers
          : document.loadBookmarks
    });

  // -------------- /settings

  document.resizeWindow();

  // はじめからnoticeが設定されていたら表示
  if ($('#notice').html()) {
    document.showNotice();
  }
  if ($('#bookmarks').is('.firstload')) {
    document.loadBookmarks();
  }

  $(window).on('load', () => {
    const paramStr = window.location.href.split('?')[1];
    if (paramStr && !chrome.app.isInstalled) {
      const params = paramStr.split('&');
      // パラメータ用の配列を用意
      const paramArray = [];
      // 配列にパラメータを格納
      let i = 0;
      while (i < params.length) {
        const neet = params[i].split('=');
        paramArray.push(neet[0]);
        // eslint-disable-next-line
        paramArray[neet[0]] = neet[1];
        i += 1;
      }
      if (paramArray.from === 'extension_installing') {
        $.cookie('plugin-dialog-disabled', 'true');
      } else if (paramArray.signup === 'true') {
        if ($.cookie('plugin-dialog-disabled') !== -1) {
          $.removeCookie('plugin-dialog-disabled');
        } else {
          const html = `\
          <div id="plugin-dialog-inner">
            <h2>Take easy screenshots directly with a chrome extension.</h2>
            <p>
              With the official DRAWWWERS chrome extension it’s easy to create a new bookmark with a screenshot.
            </p>
            <a class="btn" onclick="chrome.webstore.install('https://chrome.google.com/webstore/detail/${process.env.EXTENSION_ID}', () => location.href = location.href.split('?')[0];}, function(e){console.error(e);});">
              Install
            </a>
            <a class="cancel-btn" onclick="location.href = location.href.split('?')[0];">Not now</a>
          </div>\
          `;
          document.showDialog('Drawwwers Chrome Extension', html, 370);
          $('body').addClass('no-scroll');
        }
      }
    }
  });

  document.finishLoading();
});

document.loadBookmarksCount = 0;
document.loadBookmarks = () => {
  const $bookmarks = $('#bookmarks');
  if (!$bookmarks.is('[loading]')) {
    $bookmarks.attr('loading', '');

    if ($('#filterNav>span').text() === 'Popular') {
      // Popularページは、日付順じゃないのでoffsetを指定
      $.ajax({
        url: window.location.pathname,
        data: {
          offset: $bookmarks.children('.bookmark').length,
          num: howManyLoad()
        },
        dataType: 'script'
      });
    } else {
      $.ajax({
        url: window.location.pathname,
        data: {
          start: $bookmarks.children('.bookmark:last').data('date') || null,
          num: howManyLoad()
        },
        dataType: 'script'
      });
    }

    document.loadBookmarksCount += 1;
    if (isset(ga) && 1 < document.loadBookmarksCount) {
      ga(
        'gtm2.send',
        'event',
        'Bookmark list',
        'Loading',
        `Load bookmarks on ${$('body').data('page-name')} page`,
        document.loadBookmarksCount
      );
    }
  }
};

document.loadUsers = () => {
  const $users = $('#userList');
  if (!$users.is('[loading]')) {
    $users.attr('loading', '');

    $.ajax({
      url: window.location.pathname,
      data: {
        offset: $users.children('.user').length
      },
      dataType: 'script'
    });
  }
};

document.showDialog = (_title, _html, _width) => {
  const $dialog = $('#dialog');

  // ajaxロード中のスタイル解除
  $('body,.bookmark').removeClass('loading');

  // TODO: 将来的には、jsの関数で分岐させずに、CSSのみで、widthを指定したほうが良いかも。
  const dialogWidth = _width || 682;
  $dialog.html(_html).css('width', `${dialogWidth}px`);

  // set title
  if (!$dialog.find('.dialog_titlebar').length) {
    $dialog.prepend(
      '<div class="dialog_titlebar"><div class="dialog_title"></div></div><div class="dialog_title_border"></div>'
    );
  }
  $dialog.find('.dialog_title').text(_title);

  $('body').addClass('no-scroll');
  $('#overlay').addClass('visible');
};

document.showNotice = _html => {
  const notice = $('#notice');
  if (_html) {
    notice.html(_html);
  }
  notice
    .show()
    .delay(2000)
    .fadeOut('slow');
};

document.finishLoading = () => {
  // https://stackoverflow.com/questions/3877027/jquery-callback-on-image-load-even-when-the-image-is-cached
  $('.usericon')
    .on('load', e => {
      $(e.currentTarget).removeClass('loading');
    })
    .each((i, el) => {
      if (el.complete) {
        $(el).load();
      }
    });
  // 2019.12以前のブックマークに関してはretina画像がないので、srcsetのerrorを検知して、予め1x画像をセットしたsrcからの読み込みに切り替える
  $('img.thumb').on('error', () => {
    if ($(this).attr('srcset') !== undefined) {
      $(this).removeAttr('srcset');
    }
  });
};

document.resizeWindow = () => switchView();
