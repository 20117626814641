// css
import 'jquery-ui/themes/base/all.css';
// TODO: Bootstrap4(vue-bootsrap)にアップグレード
// import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/stylesheets/lib/bootstrap-combined.min.css';
import '../src/stylesheets/lib/bootstrap-responsive.min.css';
import '../src/stylesheets/application.sass';

// js
import '../src/js/index';

// eslint-disable-next-line
const images = require.context('./../src/images/', true);
